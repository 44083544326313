const currencyCodes = []
const FORMATTERS = {}

const formatMoney = (amount, currencyCode) => {
  if (!FORMATTERS[currencyCode]) {
    FORMATTERS[currencyCode] = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    currencyCodes.push(currencyCode)
  }
  return FORMATTERS[currencyCode].format(amount)
}

export { formatMoney }
